<script setup lang="ts">
import type { MenuItem } from 'primevue/menuitem';

import { MetricAPI } from '~/utils/api/metric';

type MainMenuItem = {
  label?: string;
  divider?: boolean;
  to?: string;
  click?: (event: MouseEvent) => void;
  icon?: string;
};

const route = useRoute();
const store = useMainStore();
const { t } = useI18n();

const menu = ref();
const DESKTOP_MENU: MainMenuItem[] = [
  { label: t('navigation.home'), to: store.isPWA ? '/dashboard' : '/' },
  { label: t('navigation.about_sex_tasks'), to: '/about-fants' },
  { label: t('navigation.play_sex_tasks'), to: '/game/settings' },
  {
    label: t('navigation.get_to_know_partner'),
    icon: 'pi pi-angle-down',
    click: (event: MouseEvent) => menu.value?.toggle(event),
  },
  { label: t('navigation.articles'), to: '/articles' },
];
const INTERESTS_MENU: MenuItem[] = [
  { label: t('navigation.about_interests_game'), to: '/about-fants#interests' },
  { label: t('navigation.play_in_the_interests'), to: '/interests' },
  { label: t('navigation.show_matches'), to: '/interests/results/compare' },
  { label: t('navigation.select_questions'), to: '/interests/packs' },
  { label: t('navigation.edit_answers'), to: '/interests/change' },
];

const badgeCount = computed(
  () =>
    +!store.user.pairedUser +
    +!store.user.isEmailVerified +
    +!store.user.isTwoFactorAuthenticationEnabled,
);

const onSupport = () => {
  MetricAPI.create({
    body: {
      type: 'support_button_clicked',
      data: { page: route.path },
    },
  });
};
</script>

<template>
  <header class="topMenu shadow-4 flex align-items-center px-3 md:px-4">
    <div class="align-items-center none xl:flex gap-1 xxl:gap-2">
      <template v-for="item in DESKTOP_MENU">
        <NuxtLink
          v-if="item.to"
          :key="`link_${item.label}`"
          :to="item.to"
          class="block"
        >
          <Button
            :label="item.label"
            :icon="item.icon"
            plain
            text
            icon-pos="right"
            class="px-2 xl:px-3"
          />
        </NuxtLink>
        <Button
          v-else
          :key="`button_${item.label}`"
          @click="item.click"
          plain
          text
          :label="item.label"
          :icon="item.icon"
          icon-pos="right"
          class="px-2 xl:px-3"
        />
      </template>
      <Menu ref="menu" :model="INTERESTS_MENU" :popup="true">
        <template #item="{ item, props }">
          <router-link
            v-slot="{ href, navigate }"
            :to="item.to as string"
            custom
          >
            <a v-ripple :href="href" v-bind="props.action" @click="navigate">
              {{ item.label }}
            </a>
          </router-link>
        </template>
      </Menu>
    </div>
    <LayoutMobileMenu />

    <NuxtLink
      to="https://boosty.to/sex-play"
      external
      target="_blank"
      class="ml-1 xl:ml-auto xl:mr-2"
    >
      <Button
        @click="onSupport"
        :label="t('support')"
        severity="warn"
        outlined
        rounded
        badge="$"
      />
    </NuxtLink>

    <div class="ml-auto xl:ml-0 flex align-items-center gap-2">
      <LayoutPairedGameStatus />
      <LayoutSexStatuses />
      <Tag
        tabindex="0"
        v-if="store.isAuthorized"
        value="pro"
        class="topMenu__pro"
      />
      <NuxtLink
        v-if="!store.isAuthorized"
        to="/auth/sign-in"
        class="block"
        data-test-id="head-sign-in-link"
      >
        <Button text class="px-2 sm:px-3">
          {{ t('login') }}
        </Button>
      </NuxtLink>
      <template v-else>
        <OverlayBadge v-if="badgeCount" :value="badgeCount" severity="danger">
          <NuxtLink
            to="/profile"
            class="block"
            data-test-id="head-profile-link"
          >
            <Button raised :aria-label="t('go_to_profile')">
              <span class="lg:block mr-2" style="display: none">{{
                store.user.name
              }}</span>
              <i class="pi pi-user" />
            </Button>
          </NuxtLink>
        </OverlayBadge>
        <NuxtLink v-else to="/profile" class="block">
          <Button raised :aria-label="t('go_to_profile')">
            <span class="lg:block mr-2" style="display: none">
              {{ store.user.name }}
            </span>
            <i class="pi pi-user" />
          </Button>
        </NuxtLink>
      </template>
    </div>
  </header>
</template>

<i18n src="~/locales/navigation.yaml"></i18n>
<i18n>
en:
  login: Login
  go_to_profile: Go to profile
  support: Support

ru:
  login: Войти
  go_to_profile: Перейти в профиль
  support: Поддержать
</i18n>

<style lang="scss">
.topMenu {
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: var(--p-surface-900);

  &__pro {
    background-color: gold !important;
    cursor: pointer;
  }
}
</style>
