<script setup lang="ts">
type MainMenuItem = {
  label?: string;
  divider?: boolean;
  to?: string;
  click?: (event: MouseEvent) => void;
  icon?: string;
  testId?: string;
};

const store = useMainStore();
const route = useRoute();
const { t } = useI18n();

const MOBILE_MENU: MainMenuItem[] = [
  { label: t('navigation.home'), to: store.isPWA ? '/dashboard' : '/' },
  { divider: true },
  { label: t('navigation.about_sex_tasks'), to: '/about-fants' },
  { label: t('navigation.play_sex_tasks'), to: '/game/settings', testId: 'menu-game-link' },
  { divider: true },
  { label: t('navigation.about_interests_game'), to: '/about-fants#interests' },
  { label: t('navigation.play_in_the_interests'), to: '/interests' },
  { label: t('navigation.show_matches'), to: '/interests/results/compare' },
  { label: t('navigation.select_questions'), to: '/interests/packs' },
  { label: t('navigation.edit_answers'), to: '/interests/change' },
  { divider: true },
  { label: t('navigation.articles'), to: '/articles' },
];

const isMoved = ref(false);
const showMenu = ref(false);
const touchStartXPosition = ref<number>(0);
const touchDeltaXPosition = ref<number>(0);
const menuContainer = ref<HTMLDivElement>();

const path = computed(() => route.fullPath);

const normalizeXPosition = (event: TouchEvent | MouseEvent) => {
  return 'touches' in event ? event.touches[0].clientX : event.clientX;
};

const onTouchMove = (event: TouchEvent | MouseEvent) => {
  const xPosition = normalizeXPosition(event);

  touchDeltaXPosition.value = Math.min(
    0,
    xPosition - touchStartXPosition.value,
  );
  isMoved.value = true;
};

const onTouchEnd = (event: Event) => {
  if (isMoved.value) event.preventDefault();
  if (touchDeltaXPosition.value < -150) {
    setTimeout(() => {
      showMenu.value = false;
    });
  }
  touchStartXPosition.value = 0;
  touchDeltaXPosition.value = 0;
  setTimeout(() => {
    isMoved.value = false;
  });

  if (menuContainer.value) {
    menuContainer.value.removeEventListener('touchmove', onTouchMove);
    menuContainer.value.removeEventListener('mousemove', onTouchMove);
    menuContainer.value.removeEventListener('touchend', onTouchEnd);
    menuContainer.value.removeEventListener('mouseup', onTouchEnd);
  }
};

const onTouchStart = (event: TouchEvent | MouseEvent) => {
  if (!showMenu.value) return;

  touchStartXPosition.value = normalizeXPosition(event);

  if (menuContainer.value) {
    menuContainer.value.addEventListener('touchmove', onTouchMove);
    menuContainer.value.addEventListener('mousemove', onTouchMove);
    menuContainer.value.addEventListener('touchend', onTouchEnd);
    menuContainer.value.addEventListener('mouseup', onTouchEnd);
  }
};

const onOverflowClick = () => {
  if (!isMoved.value) showMenu.value = false;
};

watch(path, () => {
  showMenu.value = false;
});

watch(showMenu, (value) => {
  if (value) document.body.classList.add('p-overflow-hidden');
  else document.body.classList.remove('p-overflow-hidden');
});

onMounted(() => {
  if (menuContainer.value) {
    menuContainer.value.addEventListener('touchstart', onTouchStart, {
      passive: true,
    });
    menuContainer.value.addEventListener('mousedown', onTouchStart, {
      passive: true,
    });
  }
});

onBeforeUnmount(() => {
  if (menuContainer.value) {
    menuContainer.value.removeEventListener('touchstart', onTouchStart);
    menuContainer.value.removeEventListener('mousedown', onTouchStart);
  }
});
</script>

<template>
  <Button
    icon="pi pi-bars"
    text
    plain
    class="xl:hidden"
    @click="showMenu = !showMenu"
    :aria-label="t('open')"
    data-test-id="toggle-mobile-menu"
  />

  <div ref="menuContainer">
    <Transition name="fade">
      <div
        v-if="showMenu"
        class="mobileMenu__overflow"
        @click="onOverflowClick"
      />
    </Transition>
    <Transition name="sidebar">
      <aside
        v-if="showMenu"
        class="surface-section h-screen flex flex-column select-none top-0 left-0 fixed mobileMenu__menu"
        :style="
          touchStartXPosition
            ? `transform: translateX(${touchDeltaXPosition}px)`
            : 'transition: transform 0.25s ease'
        "
      >
        <Button
          @click="showMenu = false"
          icon="pi pi-times"
          text
          rounded
          class="absolute text-900"
          style="top: 16px; right: 16px"
          :aria-label="t('close')"
        />
        <nav
          class="overflow-y-auto min-h-full p-2 flex flex-column justify-content-center gap-2"
        >
          <template v-for="item in MOBILE_MENU">
            <Divider :key="`divider_${item.to}`" v-if="item.divider" />
            <NuxtLink
              :key="`link_${item.to}`"
              v-else
              :to="item.to"
              class="block w-full"
              :data-test-id="item.testId"
            >
              <Button
                plain
                text
                :label="item.label"
                size="large"
                class="text-center w-full"
              />
            </NuxtLink>
          </template>
        </nav>
      </aside>
    </Transition>
  </div>
</template>

<i18n src="~/locales/navigation.yaml"></i18n>
<i18n>
en:
  open: Open menu
  close: Close menu

ru:
  open: Открыть меню
  close: Закрыть меню
</i18n>

<style lang="scss">
.mobileMenu {
  &__overflow {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 11;
    transition: opacity 0.5s ease;
  }

  &__menu {
    z-index: 12;
    width: 300px;
  }
}
</style>
