<script lang="ts" setup>
const { active } = defineProps<{ active?: boolean }>();
</script>

<template>
  <svg
    class="fireIcon"
    :class="{ fireIcon_active: active }"
    viewBox="0 0 100 100"
    y="0"
    x="0"
    width="200px"
    height="200px"
  >
    <g
      class="ldl-scale"
      style="transform-origin: 50% 50%; transform: rotate(0deg) scale(0.8, 0.8)"
    >
      <g class="ldl-ani">
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            :style="
              active
                ? 'transform: scale(0.91); transform-origin: 50px 50px; animation: 1.11111s linear -0.740741s infinite normal forwards running fire-animation;'
                : undefined
            "
          >
            <path
              fill="#c33737"
              d="
                M80.8 22c-3.7 1.6-7.4 3.9-10.8 6.7-5.5-6.7-10.2-13.4-9.3-21.2C49.5 12.1 38.6 18.9 30.3
                27c-.6-2-.9-4.1-1-6.4-12 10.9-16.3 30.6-12.7 46 8 38.6 64.6 31.8 67.5-6.2C87 45.6 77.4 34.7 80.8 22z
              "
              style="fill: rgb(195, 55, 55)"
            />
          </g>
        </g>
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            :style="
              active
                ? 'transform: scale(0.91); transform-origin: 50px 50px; animation: 1.11111s linear -0.925926s infinite normal forwards running fire-animation;'
                : undefined
            "
          >
            <path
              fill="#f47e60"
              d="
                M65.9 47c-2 1-3.6 7.7-5.4 9.4-3.3-3.4-6.5-12.1-6.4-16.3-6.1 3-11.5 12.4-15.7 17.1-.4-1-1.1-7.5-1.2-8.7-6.2
                6.3-7.8 17-5.1 25 6.1 20.2 37.2 14 37.2-6.3 1.1-8-4.7-13.4-3.4-20.2z
              "
              style="fill: rgb(244, 126, 96)"
            />
          </g>
        </g>
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            :style="
              active
                ? 'transform: scale(0.91); transform-origin: 50px 50px; animation: 1.11111s linear -1.11111s infinite normal forwards running fire-animation;'
                : undefined
            "
          >
            <ellipse
              fill="#f8b26a"
              ry="4.8"
              rx="8.2"
              cy="76"
              cx="49.5"
              style="fill: rgb(248, 178, 106)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<style lang="scss">
.fireIcon {
  height: 100%;
  width: 100%;
  filter: grayscale(100%);

  &_active {
    filter: initial;
  }
}
</style>
